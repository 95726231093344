import "./Login.css";
import CusTextField from "../components/text-field/text-field";
import { Button, Collapse, Divider, Stack } from "@mui/material";
import { MDBCard, MDBCardImage } from "mdb-react-ui-kit";
import logo from "../../../pictures/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Toast } from "../../alert";
import Spinner from "../../spinner";
import request from "superagent";
import { appMainColor, baseApiUrl } from "../../../services/setup";

const MobileLogin = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [progress, setProgress] = useState(false);

  const handleSubmit = async () => {
    if (validateInputs()) {
      setProgress(true);

      if (navigator.onLine) {
        // fetch("https://www.bing.com", { mode: "no-cors" })
        //   .then(async () => {
        const req = {
          id: email,
          password: password,
        };

        await request
          .post(baseApiUrl + "login.php")
          .type("application/json")
          .send(req)
          .then((response) => {
            const resData = response.body;
            navigate("/mob-dashboard", { state: { data: resData } });

            localStorage.setItem("email", email);
            localStorage.setItem("pin", response.body.agent);
            localStorage.setItem("userName", response.body.firstname);
            localStorage.setItem("pin", response.body.pin);
            localStorage.setItem("balance", response.body.accountBalance);
            localStorage.setItem("agent", response.body.userAgent);

            // setting login session
            localStorage.setItem("lastActivityTime", Date.now().toString());
          })
          .catch((err) => {
            setProgress(false);

            Toast.fire({
              icon: "error",
              title: err,
            });
          });
        // });
        // .catch((err) => {
        //   setProgress(false);

        //   Toast.fire({
        //     icon: "error",
        //     title: "Seems like you have a poor network, please try again.",
        //   });
        // });
      } else {
        Toast.fire({
          icon: "error",
          title: "No network, please check your data and try again",
        });

        setProgress(false);
      }
    }
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const validateInputs = () => {
    if (email === "") {
      Toast.fire({
        icon: "error",
        title: "Email must be provided",
      });

      return false;
    }

    if (password === "") {
      Toast.fire({
        icon: "error",
        title: "Password must be provided",
      });

      return false;
    }

    return true;
  };

  return (
    <div style={{ background: appMainColor }} className="login">
      <div className="head-section">
        <MDBCard
          // onClick={handleLogoClick}
          className="shadow-2 p-4"
          style={{ cursor: "pointer", width: "100px" }}
        >
          <MDBCardImage position="top" src={logo}></MDBCardImage>
        </MDBCard>

        <br />
        {/* <span id="welcome-note">Welcome back, User</span> */}
      </div>

      <div className="body-section">
        <span id="login-txt">LOGIN</span>

        <CusTextField
          onChange={(e) => {
            setEmail(e);
          }}
          label="Email"
          placeholder="Your login email"
          type="email"
          className="cus-text-field"
        />

        <CusTextField
          label="Password"
          placeholder="Your password"
          type="password"
          className="cus-text-field"
          onChange={(e) => {
            setPassword(e);
          }}
        />

        <div className="mb-2">
          <Collapse in={progress}>
            <Spinner />
          </Collapse>
        </div>

        <Button
          onClick={() => {
            handleSubmit();
          }}
          className="w-50 mb-2"
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "12",
            background: "#a8518a",
            borderRadius: "20px",
            "&:hover": { background: "#a8518a" },
            height: 35,
          }}
        >
          Login
        </Button>

        <Link
          style={{ fontSize: "12px" }}
          className="d_link p-2"
          to={""}
          onClick={() => {
            // setReset(true);
          }}
        >
          Forgot Password?
        </Link>

        <div style={{ fontSize: "12px" }}>
          Not yet registered?
          <b>
            <Link className="d_link p-2" to="/mob-register">
              Register here
            </Link>
          </b>
        </div>
      </div>
    </div>
  );
};

export default MobileLogin;
