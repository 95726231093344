import Component1 from "./NavMenuItem";
import Component from "./MenuItem";
import PropTypes from "prop-types";
import "./MenuComponent.css";
import dataIcn from "../../../pictures/icn/data_icn.png";
import airtimeIcn from "../../../pictures/icn/airtime_icn.png";
import tvIcn from "../../../pictures/icn/tv_icn.png";
import airtimeToCashIcn from "../../../pictures/icn/to_cash_icn.png";
import electricIcn from "../../../pictures/icn/electric_icn.png";
import resultIcn from "../../../pictures/icn/pin_icn.png";
import MenuItem from "./MenuItem";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

const MenuComponent = () => {
  const navigate = useNavigate();

  return (
    <MDBContainer>
      <div className="nav-card">
        <MDBRow>
          <MDBCol className="d-flex justify-content-center align-items-center">
            <MenuItem
              handleClick={() => {
                navigate("/mob-data");
              }}
              labelText="Internet Data"
              icon={dataIcn}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center align-items-center">
            <MenuItem
              handleClick={() => {
                navigate("/mob-airtime");
              }}
              labelText="Buy Airtime"
              icon={airtimeIcn}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center align-items-center">
            <MenuItem
              handleClick={() => {
                navigate("/mob-electric");
              }}
              labelText="Electric Sub"
              icon={electricIcn}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="d-flex justify-content-center align-items-center">
            <MenuItem
              handleClick={() => {
                navigate("/mob-tv-sub");
              }}
              labelText="TV Subscription"
              icon={tvIcn}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center align-items-center">
            <MenuItem
              handleClick={() => {
                navigate("/mob-airtime-to-cash");
              }}
              labelText="Airtime to Cash"
              icon={airtimeToCashIcn}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center align-items-center">
            <MenuItem
              handleClick={() => {
                navigate("/mob-result");
              }}
              labelText="Results Pin"
              icon={resultIcn}
            />
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
};

export default MenuComponent;
