import { Button } from "@mui/material";
import "./DashboardHeader.css";
import moonIcn from "../../../pictures/icn/moon_icn.png";
import profileIcn from "../../../pictures/icn/profile_icn.png";

const DashboardComponent = () => {
  return (
    <div id="header-container">
      <img id="dsh-profile-img" loading="lazy" alt="" src={profileIcn} />
      <div id="greetings">
        <div class="horizontal-align">
          <div>Good evening</div>
          <img class="moon-and-stars" loading="lazy" alt="" src={moonIcn} />
        </div>
        <div style={{ fontWeight: "bolder" }}>
          {localStorage.getItem("userName")}
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
