import "./Login.css";

import "./MobSupport.css";
import callUsImg from "../../../pictures/call_us_bg.png";
import contactUsImg from "../../../pictures/contact_us.png";
import chatIco from "../../../pictures/chat.png";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import {
  contactAddress,
  contactEmail,
  contactNumber,
} from "../../../services/setup";

const MobileSupport = () => {
  return (
    <div
      style={{ height: "100vh" }}
      className="overlay d-flex flex-column align-items-center"
    >
      <img
        style={{ width: "200px", height: "150px", top: 0 }}
        src={contactUsImg}
      />

      <section
        style={{
          width: "85%",
          margin: "20px auto",
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2>Need Support:</h2>
        <p>For inquiries and more information, please contact us at:</p>
        <address>
          Email: {contactEmail}
          <br />
          Phone: {contactNumber}
          <br />
          Address: {contactAddress}
        </address>
      </section>
    </div>
  );
};

export default MobileSupport;
