import { Button } from "@mui/material";
import "./WalletComponent.css";
import moonIcn from "../../../pictures/icn/moon_icn.png";
import profileIcn from "../../../pictures/icn/profile_icn.png";
import { useNavigate } from "react-router-dom";

const WalletComponent = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="main-wallet-container"
      style={{ padding: "12px", fontSize: "10px" }}
    >
      <div className="wallet-balance-title-wrapper">
        <div className="wallet-balance-title">
          <b>YOUR LEMONDATA</b>
          <br />

          <div className="wallet-balance">Wallet balance</div>
          <div className="balance-amount-wrapper">
            <div className="balance-amount"> N {props.balance} </div>
          </div>
        </div>
      </div>
      <div className="fund-transactions-title-wrapper">
        <div className="fund-transactions-title">
          <Button
            className="transactions-label"
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "9px",
              background: "#a8518a",
              borderRadius: "5px",
              "&:hover": { background: "#a8518a" },
              marginRight: "5px",
            }}
            onClick={props.getFunding}
          >
            Fund wallet
          </Button>
          <Button
            onClick={() => {
              navigate("/mob-transactions");
            }}
            className="transactions-label1"
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "9px",
              background: "#a8518a",
              borderRadius: "5px",
              "&:hover": { background: "#a8518a" },
              marginLeft: "5px",
            }}
          >
            Transactions
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WalletComponent;
