import { useContext, useEffect, useState } from "react";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useRef } from "react";
import "firebase/compat/storage";
import { firebase } from "../../services/config";
import "firebase/compat/database";
import { Alert, Snackbar, TextField } from "@mui/material";
import { ServiceComContext } from "../dashboard";
import Swal from "sweetalert2";
import request from "superagent";
import { Navigate, useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import { appMainColor, baseApiUrl } from "../../services/setup";

const db = firebase.database();

export default function BuyDataComponent() {
  const navigate = useNavigate();
  const networks = [
    { network: "MTN", id: 1 },
    { network: "AIRTEL", id: 4 },
    { network: "GLO", id: 2 },
    { network: "9MOBILE", id: 3 },
  ];

  const userId = localStorage.getItem("email");
  const userAgent = localStorage.getItem("agent");
  const userPin = localStorage.getItem("pin");

  const [network, setNetwork] = useState(networks[0].network);
  const [networkId, setNetworkId] = useState(networks[0].id);
  const [planType, setPlanType] = useState("choose plan");
  const [dataPlan, setDataPlan] = useState("choose data plan");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dataPlanId, setDataPlanId] = useState(1);
  const [unit, setDataUnit] = useState("");

  const [submit, setSubmit] = useState(false);
  const [services, setServices] = useState([]);
  const [plansType, setPlansType] = useState([]);
  const [dataPlans, setDataPlans] = useState([]);
  const [dataPlanIds, setDataPlanIds] = useState([]);
  const [units, setUnits] = useState([]);
  const [price, setPrice] = useState(0);
  const [portedNumber, setPortedNumber] = useState(false);

  const [prices, setPrices] = useState([]);
  const [init, setInit] = useState(false);
  const [order, setOrder] = useState({});
  const [validity, setvalidity] = useState(false);

  const refBuydata = useRef(null);

  useEffect(() => {
    if (navigator.onLine) {
      fetch("https://www.google.com/", { mode: "no-cors" })
        .then(async () => {
          await request
            .get(baseApiUrl + "pricing/prices.php")
            .type("application/json")
            .then((response) => {
              const prices = response.body;
              setServices(prices);
            })
            .catch((err) => {
              let errorMsg = "";

              if (err.response && err.response.status === 400) {
                // console.log("ERROR HERE", err.response.text);
                errorMsg = err.response.text;
              } else {
                // console.error("Network error:", err);
                errorMsg = err;
              }

              Swal.fire({
                title: "Error",
                text: "Data price loading error try again or contact admin",
                icon: "error",
              });
            });
        })
        .catch(() => {});
    }
  }, [network, planType]);

  useEffect(() => {
    if (!init) {
      refBuydata.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      setInit(true);
    }
  }, [init]);

  const planTypeSearch = async (net) => {
    let plans = [];

    services.forEach((servicePack) => {
      let isExisting;
      if (servicePack.network === net) {
        try {
          plans.forEach((plan) => {
            if (servicePack.planType == plan) {
              isExisting = true;
              throw "already exist";
            }
          });
        } catch (e) {}

        if (isExisting !== true) {
          if (servicePack.planType !== "DATA CARD") {
            plans.push(servicePack.planType);
          }
        }
      }
    });

    setPlansType(plans);
    setPlanType(plans[0]);
  };

  const handleBuyData = () => {
    withReactContent(Swal).fire({
      title: "Enter your Pin",
      html:
        "You are about to send " +
        unit +
        " " +
        planType +
        " " +
        "Data" +
        " to " +
        phoneNumber,
      input: "password",
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: () => {
        if (Swal.getInput().value === userPin) {
          Swal.fire({
            title: "Processing...",
            html: "Please wait while we process your transaction",
            allowOutsideClick: false,
            allowEscapeKey: false,
            timerProgressBar: false,
            didOpen: async () => {
              Swal.showLoading();

              const req = {
                userId: userId,
                regBody: {
                  network: networkId,
                  mobile_number: phoneNumber,
                  plan: dataPlanId,
                  Ported_number: portedNumber,
                },
                amount: price,
                service: "data",
                planName: "",
                update: "v1",
                agent: userAgent,
              };

              await request
                .post(baseApiUrl + "data.php")
                .type("application/json")
                .send(req)
                .then((response) => {
                  if (response.body.status === "success") {
                    localStorage.setItem("balance", response.body.newBalance);

                    Swal.fire({
                      title: "Success",
                      text: response.body.message,
                      icon: "success",
                    }).then(navigate("/dashboard"));
                  }
                })
                .catch((err) => {
                  let errorMsg = "";

                  if (err.response && err.response.status === 400) {
                    // console.log("ERROR HERE", err.response.text);
                    errorMsg = err.response.text;
                  } else {
                    // console.error("Network error:", err);
                    errorMsg = err;
                  }

                  Swal.fire({
                    title: "Error",
                    text: errorMsg,
                    icon: "error",
                  });
                });
            },
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "The code provided is incorrect, please  check and try again",
            icon: "error",
          }).then(() => {
            handleBuyData();
          });
        }
      },
    });
  };

  useEffect(() => {
    dataPlanSearch(planType);
  }, [plansType]);

  const dataPlanSearch = (pln) => {
    let plans = [];
    let planIds = [];
    let prices = [];
    let units = [];

    services.forEach((servicePack) => {
      let isExisting;
      if (servicePack.network === network) {
        if (servicePack.planType === pln) {
          let dataPlan =
            servicePack.unit +
            " - " +
            servicePack.price +
            " - " +
            servicePack.validity;

          plans.push(dataPlan);
          planIds.push(servicePack.planID);
          prices.push(servicePack.price);
          units.push(servicePack.unit);
        }
      }
    });

    setDataPlans(plans);
    setDataPlanIds(planIds);
    setPrices(prices);
    setUnits(units);

    setDataPlan(plans[0]);
    setDataUnit(units[0]);
    setPrice(prices[0]);
    setDataPlanId(planIds[0]);
  };

  const validateInput = () => {
    if (network === "choose beneficiary network") return false;
    if (planType === "choose plan") return false;
    if (dataPlan === "choose data plan") return false;
    if (phoneNumber === "") return false;

    return true;
  };

  return (
    <div
      className="d-flex flex-column align-items-center mt-4"
      ref={refBuydata}
      style={{ padding: "30px" }}
    >
      <MDBCardBody>
        <MDBCardText>
          <h3>BUY DATA</h3>
        </MDBCardText>
      </MDBCardBody>
      <MDBCard className="center service-container shadow-2 m-2">
        <MDBDropdown>
          <MDBDropdownToggle
            style={{ background: appMainColor, color: "white" }}
          >
            NETWORK
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            {networks.map((e, index) => {
              return (
                <MDBDropdownItem
                  key={index}
                  onClick={() => {
                    setNetwork(e.network);
                    setNetworkId(e.id);
                    planTypeSearch(e.network);
                  }}
                  link
                  childTag="button"
                >
                  {e.network}
                </MDBDropdownItem>
              );
            })}
          </MDBDropdownMenu>
        </MDBDropdown>
        <MDBCardBody>
          <MDBCardText>{network}</MDBCardText>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="center service-container shadow-2">
        <MDBDropdown>
          <MDBDropdownToggle
            style={{ background: appMainColor, color: "white" }}
          >
            Plan Type
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            {plansType.map((planTyp, i) => {
              return (
                <MDBDropdownItem
                  key={i}
                  onClick={() => {
                    setPlanType(planTyp);
                    dataPlanSearch(planTyp);
                  }}
                  link
                  childTag="button"
                >
                  {planTyp}
                </MDBDropdownItem>
              );
            })}
          </MDBDropdownMenu>
        </MDBDropdown>
        <MDBCardBody>
          <MDBCardText>{planType}</MDBCardText>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="shadow-2 service-container center m-2">
        <MDBDropdown>
          <MDBDropdownToggle
            style={{ background: appMainColor, color: "white" }}
          >
            Data Plan
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            {dataPlans.map((dataPln, index) => {
              return (
                <MDBDropdownItem
                  key={index}
                  onClick={(e) => {
                    setDataPlanId(dataPlanIds[index]); // send to api
                    setDataPlan(dataPlans[index]); // this will be used for user validation
                    setDataUnit(units[index]);
                    setPrice(prices[index]);
                  }}
                  link
                  childTag="button"
                >
                  {dataPln}
                </MDBDropdownItem>
              );
            })}
          </MDBDropdownMenu>
        </MDBDropdown>
        <MDBCardBody>
          <MDBCardText>{dataPlan}</MDBCardText>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="shadow-2 service-container ">
        <TextField
          className="m-3 w-2"
          label="Beneficiary phone number"
          type="number"
          InputProps={{
            inputProps: {
              maxLength: 11,
              style: { textAlign: "center" },
            },
          }}
          onBlur={(e) => {
            setPhoneNumber(e.target.value);
          }}
        />
      </MDBCard>
      <MDBCard className="shadow-2 service-container my-2 p-4">
        <label>
          <input
            type="checkbox"
            checked={portedNumber}
            onChange={(e) => {
              setPortedNumber(e.target.checked);
            }}
            style={{ marginRight: "10px" }}
          />
          Bypass Ported number
        </label>
      </MDBCard>
      <MDBBtn
        onClick={() => {
          if (validateInput()) {
            handleBuyData();
          } else {
            setvalidity(true);
          }
        }}
        className="m-2 button"
        style={{ background: appMainColor }}
      >
        BUY DATA
      </MDBBtn>
      <Snackbar
        open={validity}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={6000}
        onClose={() => {
          setvalidity(false);
        }}
      >
        <Alert severity="error" sx={4}>
          Invalid input please check.
        </Alert>
      </Snackbar>
    </div>
  );
}
